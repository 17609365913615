<template>
  <div class="box">
    <el-form ref="addForm" :model="addForm" inline @submit.native.prevent>
      <el-form-item label="车辆">
        <el-select v-model="addForm.name" placeholder="请选择车辆" clearable>
          <el-option v-for="item in carInfoListData" :key="item.vkey" :label="item.carNum" :value="item.vkey"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="围栏">
        <el-select v-model="addForm.fenceName" placeholder="请选择围栏" clearable>
          <el-option v-for="item in findFenceListData" :key="item.thridId" :label="item.fenceName" :value="item.thridId"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="告警类型">
        <el-select v-model="addForm.type" placeholder="请选择告警类型" clearable>
          <el-option v-for="item in typeData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="告警日期">
        <el-date-picker
          v-model="addForm.time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="resetForm()">重置</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-search" @click="getLogsList()">查询</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-plus" @click="handleControl('add', '')">导出</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-plus" @click="handleControl('add', '')">导出全部</el-button>
      </el-form-item>
    </el-form>
    <el-table :header-cell-style="{ background: '#F4F4F4' }" :data="findFenceListData" style="width: 100%;">
      <el-table-column label="序号" prop="index" type="index" width="55" align="center" />
      <el-table-column label="车辆" prop="fenceIcon" min-width="55" align="center" />
      <el-table-column label="编号" prop="fenceName" align="center" />
      <el-table-column label="告警时间" prop="shapeType" align="center" />
      <el-table-column label="省份" prop="type" align="center" />
      <el-table-column label="地址" prop="fenceName" align="center" />
      <el-table-column label="告警" prop="isShowMap" align="center" />
      <el-table-column label="操作" align="center" fixed="right" width="150">
        <template #default="scope">
          <el-button size="mini" type="primary" @click="handShow(scope.row)">位置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="addForm.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="addForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="addForm.total"
      >
      </el-pagination>
    </div>
    <el-dialog title="位置查看" :visible.sync="dialogVisible" @opened="opened" custom-class="posShowBox" width="1000px">
      <div class="tip">
        <p>地址：{{ address }}</p>
        <p>经度：{{ lng }}</p>
        <p>纬度：{{ lat }}</p>
      </div>
      <div id="map"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { postRequest } from '@/api/api.js';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
export default {
  name: 'ElFenceList',
  components: {},
  data() {
    return {
      addForm: {
        name: '',
        type: '',
        time: '',
        fenceName: ''
      },
      findFenceListData: [],
      carInfoListData: [],
      typeData: [
        {
          value: 1,
          label: '进'
        },
        {
          value: 2,
          label: '出'
        },
        {
          value: 3,
          label: '进出'
        }
      ],
      dialogVisible: false,
      lat: 33.47174035503776,
      lng: 114.0691810278941,
      address: '河南省驻马店市西平县'
    };
  },
  mounted() {
    this.getFindFenceList();
    this.getLogsList();
  },

  methods: {
    initMap() {
      let that = this;
      that.map = L.map('map', {
        minZoom: 3,
        maxZoom: 19,
        center: [38, 106],
        zoom: 14,
        zoomControl: true,
        attributionControl: false,
        zoomSnap: 1,
        crs: L.CRS.EPSG3857
      });
      L.tileLayer('https://wprd02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7', { maxZoom: 22, minZoom: 1 }).addTo(
        that.map
      );

      // 障碍物标记
      let myIcon = L.icon({
        iconUrl: require('../../../assets/njglicon/1.png'),
        iconSize: [32, 32]
      });
      that.markerIcon = L.marker([that.lat, that.lng], {
        icon: myIcon,
        opacity: '1'
      }).addTo(that.map);
      that.map.setView([that.lat, that.lng]);
      // var bounds = new L.LatLngBounds([[Math.max(...lats), Math.max(...lngs)], [Math.min(...lats), Math.min(...lngs)]]);
      // that.map.flyToBounds(bounds, { padding: [100, 100]});
    },
    getLogsList() {
      console.log(this.addForm);
      let that = this;
      let data = {
        carNum: '', //车牌号 非必填
        pageNum: 1, //页码   非必填
        pageSize: 20 //每页数量   非必填
      };
      // addForm: {
      //     name: '',
      //     type: '',
      //     time: '',
      //     fenceName: '',
      // },
      postRequest('/api/iot/azlCar/carInfoList', data).then(res => {
        that.carInfoListData = res.rows;
      }).catch(error => {

      });
    },
    getCarInfoList() {
      let that = this;
      let data = {
        carNum: '', //车牌号 非必填
        pageNum: 1, //页码   非必填
        pageSize: 20 //每页数量   非必填
      };
      postRequest('/api/iot/azlCar/carInfoList', data).then(res => {
        that.carInfoListData = res.rows;
      }).catch(error => {

      });
    },
    // 新增地块 add edit show
    handleControl(type, row) {
      let that = this;
      if (type == 'edit') {
        that.$router.push({
          path: 'elFence',
          query: {
            shapeType: row.shapeType,
            lat: row.lat,
            lng: row.lng,
            id: row.id,
            type: 'edit'
          }
        });
      } else if ((type = 'add')) {
        that.$router.push({
          path: 'elFence',
          query: {
            lat: 33.38,
            lng: 114.02,
            type: 'add'
          }
        });
      } else if ((type = 'show')) {
        that.$router.push({
          path: 'elFence',
          query: {
            shapeType: 2,
            lat: row.lat,
            lng: row.lng,
            type: 'show'
          }
        });
      }
    },
    handShow(row) {
      let that = this;
      that.dialogVisible = true;
    },
    opened() {
      let that = this;
      that.initMap();
    },
    resetForm() {
      let that = this;
      that.addForm.fenceName = '';
      that.getFindFenceList();
    },
    getFindFenceList() {
      let that = this;
      let data = {
        fenceName: '',
        pageNum: 1,
        pageSize: 100
      };
      postRequest('/api/iot/azl/findFenceList', data).then(res => {
        that.findFenceListData = res.rows;
        that.addForm.total = res.total;
      }).catch(error => {

      });
    },
    handleSizeChange(val) {
      let that = this;
      that.addForm.pageSize = val;
      that.getFindFenceList();
    },
    handleCurrentChange(val) {
      let that = this;
      that.addForm.pageNum = val;
      that.getFindFenceList();
    }
  }
};
</script>
<style scoped lang="scss">
h2,
div,
body,
html,
table,
th,
tr,
td,
ul,
p {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.box {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.pagination {
  text-align: right;
  padding: 10px 0;
}
#map {
  width: 100%;
  height: 550px;
  position: relative;
  z-index: 98;
  ::v-deep .leaflet-left .leaflet-control-zoom {
    display: none;
  }
}
::v-deep .posShowBox {
  position: relative;
  .el-dialog__footer {
    text-align: center !important;
  }
  .tip {
    position: absolute;
    background: #fff;
    left: 30px;
    top: 95px;
    width: 250px;
    z-index: 99;
    padding: 10px;
    border-radius: 10px;
    p {
      line-height: 30px;
    }
  }
}
</style>
