<template>
  <div class="box">
    <el-form ref="addForm" :model="addForm" inline @submit.native.prevent>
      <el-form-item label="姓名/手机号">
        <el-input v-model="addForm.keyword" placeholder="姓名/手机号模糊搜索" clearable />
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="resetForm()">重置</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-search" @click="getCarUserInfoList()">查询</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-plus" @click="handleControl('add', '')">添加司机</el-button>
      </el-form-item>
    </el-form>
    <el-table :header-cell-style="{ background: '#F4F4F4' }" :data="carUserInfoListData" style="width: 100%;">
      <el-table-column label="序号" prop="index" type="index" width="55" align="center" />
      <el-table-column label="姓名" prop="userName" align="center" />
      <el-table-column label="手机号码" prop="mobile" align="center" />
      <el-table-column label="车辆" prop="carNum" align="center">
        <template slot-scope="scope">
          {{ scope.row.carNum || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="type" align="center">
        <template slot-scope="scope">
          {{ scope.row.status == 1 ? '正常' : '异常' }}
        </template>
      </el-table-column>
      <el-table-column label="注册时间" prop="createTime" align="center">
        <template slot-scope="scope">
          {{ getCurrentTimeStr(scope.row.createTime) }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" align="center" fixed="right" width="150">
                <template #default="scope">
                    <el-button size="mini" type="primary" @click="handleControl('edit', scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDel(scope.row.thridId)">删除</el-button>
                </template>
            </el-table-column> -->
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="addForm.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="addForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="addForm.total"
      >
      </el-pagination>
    </div>
    <el-dialog title="添加司机" :visible.sync="dialogVisible" width="500px">
      <div class="formBox">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="姓名" prop="userName" placeholder="请输入姓名">
            <el-input v-model="ruleForm.userName" clearable></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="ruleForm.sex">
              <el-radio label="1">男</el-radio>
              <el-radio label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="手机" prop="mobile" placeholder="请输入手机号">
            <el-input v-model="ruleForm.mobile" clearable></el-input>
          </el-form-item>
          <el-form-item label="车辆(选填)">
            <el-select v-model="ruleForm.vkey" placeholder="请选择">
              <el-option v-for="item in carInfoListData" :key="item.vkey" :label="item.carNum" :value="item.vkey"> </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="状态" v-if="ruleForm.shapeType == 1">
                        <el-input v-model="ruleForm.mobile" clearable></el-input>
                    </el-form-item> -->
          <div class="btn">
            <el-form-item>
              <el-button @click="resetFormD('ruleForm')">返 回</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';
export default {
  name: 'NongjiS',
  components: {},
  data() {
    return {
      addForm: {
        keyword: '',
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      carUserInfoListData: [],
      dialogVisible: false,
      ruleForm: {
        userName: '',
        sex: '1',
        mobile: '',
        vkey: ''
      },
      rules: {
        userName: [{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }],
        mobile: [{ required: true, message: '请输入手机号', trigger: ['blur', 'change'] }]
      },
      carInfoListData: []
    };
  },
  mounted() {
    this.getCarUserInfoList();
    this.getCarInfoList();
  },
  methods: {
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          // console.log(that.ruleForm)
          postRequest('api/iot/azlCar/addCarUser', that.ruleForm).then(res => {
            that.dialogVisible = false;
            that.resetFormD(formName);
            that.getCarUserInfoList();
          }).catch(error => {

      });
        } else {
          return false;
        }
      });
    },
    getCarInfoList() {
      let that = this;
      let data = {
        carNum: '', //车牌号 非必填
        pageNum: 1, //页码   非必填
        pageSize: 20 //每页数量   非必填
      };
      postRequest('api/iot/azlCar/carInfoList', data).then(res => {
        that.carInfoListData = res.rows;
      }).catch(error => {

      });
    },
    getCurrentTimeStr() {
      let addZero = function(num) {
        if (parseInt(num) < 10) {
          num = '0' + num;
        }
        return num;
      };
      var oDate = new Date(),
        oYear = oDate.getFullYear(),
        oMonth = oDate.getMonth() + 1,
        oDay = oDate.getDate(),
        oHour = oDate.getHours(),
        oMin = oDate.getMinutes(),
        oSen = oDate.getSeconds(),
        oTime = oYear + '-' + addZero(oMonth) + '-' + addZero(oDay) + ' ' + addZero(oHour) + ':' + addZero(oMin) + ':' + addZero(oSen);
      return oTime;
    },
    // 新增地块 add edit show
    handleControl(type, row) {
      let that = this;
      if (type == 'edit') {
        that.$router.push({
          path: 'elFence',
          query: {
            shapeType: row.shapeType,
            lat: row.lat,
            lng: row.lng,
            id: row.id,
            type: 'edit'
          }
        });
      } else if ((type = 'add')) {
        that.dialogVisible = true;
      } else if ((type = 'show')) {
        that.$router.push({
          path: 'elFence',
          query: {
            shapeType: 2,
            lat: row.lat,
            lng: row.lng,
            type: 'show'
          }
        });
      }
    },
    handleDel(thridId) {
      let that = this;
      this.$confirm('此操作将删除该围栏, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          postRequest('/api/iot/azl/deleteFence', { thridId: thridId }).then(res => {
            that.$message.success('删除成功');
            that.getCarUserInfoList();
          });
        })
        .catch(() => {});
    },
    resetForm() {
      let that = this;
      that.addForm.keyword = '';
      that.getCarUserInfoList();
    },
    resetFormD(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.ruleForm.vkey = '';
    },
    getCarUserInfoList() {
      console.log('#####');
      let that = this;
      postRequest('/api/iot/azlCar/carUserInfoList', that.addForm).then(res => {
        console.log(res.rows, '????');
        that.carUserInfoListData = res.rows;
        that.addForm.total = res.total;
      }).catch(error => {

      });
    },
    handleSizeChange(val) {
      let that = this;
      that.addForm.pageSize = val;
      that.getCarUserInfoList();
    },
    handleCurrentChange(val) {
      let that = this;
      that.addForm.pageNum = val;
      that.getCarUserInfoList();
    }
  }
};
</script>
<style scoped lang="scss">
h2,
div,
body,
html,
table,
th,
tr,
td,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.box {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.pagination {
  text-align: right;
  padding: 10px 0;
}
.formBox {
  width: 350px;
  text-align: left;
  margin: 10px 0 0 0;
  .el-form-item {
    margin-bottom: 20px;
  }
  .btn {
    margin: 50px 0 0 20px;
  }
}
</style>
