<!-- //无人机管理 -->
<template>
  <div>
    <el-row style="margin-bottom: 12px">
      <el-col :span="6" class="flex-row">
        <span class="span1">设备序列号</span>
        <el-input v-model="searchParams.deviceCode" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">设备类型</span>
        <el-select v-model="searchParams.deviceType" placeholder="请选择" clearable style="width: 70%">
          <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">操作时间</span>
        <el-date-picker
          style="width: 60%"
          v-model="startDate"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          clearable
        >
        </el-date-picker>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>

    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>

      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column prop="deviceCode" label="设备序列号"> </el-table-column>
      <el-table-column prop="deviceTypeName" label="设备类型"> </el-table-column>
      <el-table-column prop="optTime" label="操作时间"> </el-table-column>
      <el-table-column prop="optUserName" label="操作人"> </el-table-column>
      <el-table-column prop="optUserIp" label="操作IP"> </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { logHistory } from '@/api/api.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data() {
    return {
      typeList: [
        { id: 1, name: '移动喷灌机' },
        { id: 0, name: '固定喷灌机' },
        { id: 2, name: '摄像头' },
        { id: 8, name: '指针喷灌机' },
        { id: 12, name: '水电双计' },
        { id: 13, name: '指针喷灌井' },
        { id: 15, name: '太阳能杀虫灯' }
      ],
      startDate: [],
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    search() {
      (this.searchParams.startTime = this.startDate && this.startDate[0] ? dayjs(this.startDate[0]).format('YYYY-MM-DD HH:mm:ss') : ''),
        (this.searchParams.endTime = this.startDate && this.startDate[1] ? dayjs(this.startDate[1]).format('YYYY-MM-DD HH:mm:ss') : '');

      this.pageInfo.currentPage = 1;
      this.getList();
    },

    getList() {
      logHistory({ pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize, ...this.searchParams })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
