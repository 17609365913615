<template>
  <el-card>
    <el-form ref="addForm" :model="addForm" inline @submit.native.prevent>
      <el-form-item label="车辆名称">
        <el-input v-model="queryInfo.query" placeholder="车辆名称" clearable @clear="getUserInfo" />
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-search" @click="getUserInfo()">查询</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-plus" @click="showDialog">新增车辆</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="userlist" style="width: 100%">
      <el-table-column type="selection" width="30"> </el-table-column>

      <el-table-column prop="carNum" label="车辆" width="100"> </el-table-column>
      <el-table-column prop="groupId" label="编号" width="70"> </el-table-column>

      <el-table-column prop="groupName" label="分组"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status == '1' ? '正常' : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="carOwner" label="驾驶员">
        <template>
          <span>待绑定</span>
        </template>
      </el-table-column>
      <el-table-column prop="overspeed" label="限速"> </el-table-column>
      <el-table-column prop="carRunType" label="出勤方式">
        <template slot-scope="scope">
          <span>{{ scope.row.carRunType == '1' ? '里程' : 'ACC' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isConn" label="是否离线">
        <template slot-scope="scope">
          <span>{{ scope.row.isConn == '0' ? '离线' : '在线' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ups" label="电源状态">
        <template slot-scope="scope">
          <span>{{ scope.row.isConn == '1' ? '电源正常' : scope.row.isConn == '0' ? '切断电源' : '低点' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalMil" label="运行总公里数"> </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
          <el-button size="mini" type="warning" @click="scope.$index, scope.row">更多</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="车辆新增" :visible.sync="dialogFormVisible">
      <el-form :model="addForm">
        <el-form-item label="车辆名称" prop="carNum">
          <el-input v-model="addForm.carNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备ID" prop="deviceId">
          <el-input v-model="addForm.deviceId"></el-input>
        </el-form-item>

        <el-form-item label="出勤方式" prop="radio">
          <el-radio v-model="addForm.radio" label="1">按里程长度</el-radio>
          <el-radio v-model="addForm.radio" label="2">按ACC时长</el-radio>
        </el-form-item>
        <el-form-item label="总时长" prop="totalAcc">
          <el-input v-model="addForm.totalAcc"></el-input>
        </el-form-item>
        <el-form-item label="总里程" prop="totalMil">
          <el-input v-model="addForm.totalMil"></el-input>
        </el-form-item>

        <!-- <el-form-item label="驾驶员" prop="carOwner">
          <el-input v-model="addForm.carOwner"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCar">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑用户" :visible.sync="edmitLogFormVisible">
      <el-form :model="edmitForm" ref="edmitFormRef">
        <el-form-item label="设备ID" prop="deviceId">
          <el-input v-model="edmitForm.deviceId"></el-input>
        </el-form-item>
        <el-form-item label="车辆名称" prop="carNum">
          <el-input v-model="edmitForm.carNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出勤方式" prop="radio">
          <el-radio-group v-model="edmitForm.radio">
            <el-radio label="0">按里程长度</el-radio>
            <el-radio label="1">按ACC时长</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="总时长" prop="totalAcc">
          <el-input v-model="edmitForm.totalAcc"></el-input>
        </el-form-item>
        <el-form-item label="总里程" prop="totalMil">
          <el-input v-model="edmitForm.totalMil"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edmitLogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="edmitUser">确 定</el-button>
      </div>
    </el-dialog>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[1, 2, 3, 4]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import { postRequest } from '@/api/api.js';

export default {
  name: 'Nongji',
  data() {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 2
      },
      total: null,
      userlist: [],
      userInfo: {},
      dialogFormVisible: false,
      edmitLogFormVisible: false,
      addForm: {
        carNum: '',
        deviceId: '',
        carIconId: '',
        carRunType: '',
        totalAcc: '',
        totalMil: '',
        radio: '1',
        totalAcc: '',
        totalMil: ''
      },
      edmitForm: {
        deviceId: '',
        carNum: '',
        radio: '',
        totalAcc: '',
        totalMil: ''
      },
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      const obj = {
        carNum: 'xxx',
        pageNum: this.queryInfo.pagenum,
        pageSize: this.queryInfo.pagesize
      };
      postRequest('/api/iot/azlCar/carInfoList', obj).then(res => {
        console.log('res', res);
        this.userlist = res.rows;
        this.total = res.total;
      }).catch(error => {

      });
    },

    showDialog() {
      this.dialogFormVisible = true;
    },
    handleDelete(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          postRequest('/api/iot/azlCar/deleteCar', { vkey: 'xxx' }).then(res => {
            console.log('res1111:', res);
            this.$message.success('用户删除成功');
            this.getUserInfo();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      // await this.$http.delete(`/users${id}/`)
    },
    addCar() {
      const obj = {
        carNum: this.addForm.carNum,
        deviceId: this.addForm.deviceId, //设备序列号 定位仪的设备号
        carIconId: this.addForm.carIconId, //图标ID
        carRunType: this.addForm.carRunType,
        totalAcc: this.addForm.totalAcc,
        totalMil: this.addForm.totalMil //总里程
      };
      postRequest('/api/iot/azlCar/addCar', obj).then(res => {
        console.log('res', res);
      }).catch(error => {

      });
      console.log('添加res', res);
      this.addForm.deviceId = '';
      this.addForm.carNum = '';
      this.addForm.type = '';
      this.addForm.groupName = '';
      this.addForm.carOwner = '';
      this.dialogFormVisible = false;
      this.getUserList();
    },
    edmitUser() {},
    handleEdit(userInfo) {
      this.edmitForm = userInfo;
      this.edmitLogFormVisible = true;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      console.log('queryInfo', this.queryInfo);
      this.getUserInfo();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      console.log('newPage', newPage);
      this.getUserInfo();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
